var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{attrs:{"xl":"6","lg":"6","md":"12","sm":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('EditableCard',{attrs:{"title":"Basic","params":_vm.account_,"class_name":"Account","with_properties":['account_number', 'company_name', 'billing_account_id', 'is_main_account', 'install_date', 'invoice_remark', 'current_account_manager'],"with_relationships":[
            {
              from_class_name: 'Account',
              relationship_name: 'CreatedByAccountManager',
            },
          ]},on:{"update:params":function($event){_vm.account_=$event}}})],1)],1)],1),_c('b-col',{attrs:{"xl":"6","lg":"6","md":"12","sm":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('EditableCard',{attrs:{"title":"Contact Persons","params":_vm.account_,"relationship":_vm.$d.getRelationship('Account', 'HasContactPersons')},on:{"update:params":function($event){_vm.account_=$event}}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('EditableCard',{attrs:{"title":"Main Delivery Contact Persons","params":_vm.account_,"relationship":_vm.$d.getRelationship('Account', 'HasMainDeliveryContactPersons'),"relationship_index_params":{
            with_virtual_properties: [],
            with_relationships: [],
          }},on:{"update:params":function($event){_vm.account_=$event}}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('EditableCard',{attrs:{"title":"Contact Numbers","params":_vm.account_,"relationship":_vm.$d.getRelationship('Account', 'HasContactNumbers'),"relationship_index_params":{
            with_virtual_properties: ['type'],
            with_relationships: [],
          }},on:{"update:params":function($event){_vm.account_=$event}}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('EditableCard',{attrs:{"title":"Related To","params":_vm.account_,"relationship":_vm.$d.getRelationship('Account', 'RelatedToAccounts'),"relationship_index_params":{
            with_virtual_properties: true,
            with_relationships: [],
          }},on:{"update:params":function($event){_vm.account_=$event}}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('EditableCard',{attrs:{"title":"Related From","params":_vm.account_,"relationship":_vm.$d.getRelationship('Account', 'RelatedFromAccounts'),"relationship_index_params":{
            with_virtual_properties: true,
            with_relationships: [],
          }},on:{"update:params":function($event){_vm.account_=$event}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }