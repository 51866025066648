<template>
  <!-- <div v-if="account_"> -->
  <b-row>
    <b-col xl="6" lg="6" md="12" sm="12">
      <b-row>
        <b-col cols="12">
          <EditableCard
            title="Basic"
            :params.sync="account_"
            class_name="Account"
            :with_properties="['account_number', 'company_name', 'billing_account_id', 'is_main_account', 'install_date', 'invoice_remark', 'current_account_manager']"
            :with_relationships="[
              {
                from_class_name: 'Account',
                relationship_name: 'CreatedByAccountManager',
              },
            ]"
          />
        </b-col>

        <!-- <b-col cols="12">
          <EditableCard title="Tags" :params.sync="account_" :relationship="$d.getRelationship('Account', 'Tags')" />
        </b-col> -->
      </b-row>
    </b-col>

    <b-col xl="6" lg="6" md="12" sm="12">
      <b-row>
        <b-col cols="12">
          <EditableCard title="Contact Persons" :params.sync="account_" :relationship="$d.getRelationship('Account', 'HasContactPersons')" />
        </b-col>
        <b-col cols="12">
          <EditableCard
            title="Main Delivery Contact Persons"
            :params.sync="account_"
            :relationship="$d.getRelationship('Account', 'HasMainDeliveryContactPersons')"
            :relationship_index_params="{
              with_virtual_properties: [],
              with_relationships: [],
            }"
          />
        </b-col>
        <b-col cols="12">
          <EditableCard
            title="Contact Numbers"
            :params.sync="account_"
            :relationship="$d.getRelationship('Account', 'HasContactNumbers')"
            :relationship_index_params="{
              with_virtual_properties: ['type'],
              with_relationships: [],
            }"
          />
        </b-col>
        <b-col cols="12">
          <EditableCard
            title="Related To"
            :params.sync="account_"
            :relationship="$d.getRelationship('Account', 'RelatedToAccounts')"
            :relationship_index_params="{
              with_virtual_properties: true,
              with_relationships: [],
            }"
          />
        </b-col>
        <b-col cols="12">
          <EditableCard
            title="Related From"
            :params.sync="account_"
            :relationship="$d.getRelationship('Account', 'RelatedFromAccounts')"
            :relationship_index_params="{
              with_virtual_properties: true,
              with_relationships: [],
            }"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
  <!-- </div> -->
  <!-- <div v-else>
    <div class="alert alert-warning">Account Required</div>
  </div>-->
</template>

<script>
import _ from 'lodash';
export default {
  components: {
    EditableCard: () => import('@/descriptor/coreui/EditableCard'),
  },
  props: {
    account: {
      required: true,
    },
  },
  data: () => {
    return {};
  },
  computed: {
    account_: {
      get: function() {
        return this.account;
      },
      set: function(newValue) {
        this.$emit('update:account', newValue);
      },
    },
  },
  created() {},
  methods: {},
};
</script>
